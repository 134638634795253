import { Button } from "$/components/Button";
import { Input } from "$/components/Input";
import { LoginForm } from "$/components/LoginForm";
import { useEffect, useState } from "react";
import { useUser } from "$/state/user";
import { useAuth } from "$/state/auth";
import {
  BookingHoldState,
  useGetEnquiryQuery,
  useGetLastBookingHoldQuery,
  useGetUserByEmailQuery,
  useUpsertBookingHoldMutation,
  useUpsertEnquiryChoiceMutation,
} from "$/graphql/types.generated";
import { HtmlMeta } from "$/components/HtmlMeta";
import { ChooseAProgramme } from "../components/ChooseAProgramme";
import { ProgressBar } from "../components/ProgressBar";
import { useGetProgrammeQuizQuery } from "$/graphql/storyblok/types.generated";
import { useSearchParams } from "react-router-dom";
import { getEnquiryStates } from "../states";
import { formatProgrammeData } from "../helpers/storyblok";
import { toast } from "$/components/Toaster";
import { Countdown } from "../components/Countdown";
import { DateTime } from "luxon";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { v4 as uuid } from "uuid";
import { WEB_ASSETS_URL } from "$/configs/app.config";

interface SendData {
  key: string;
  value: string;
}

export const NoAccount = () => {
  return (
    <div className="max-w-3xl">
      <h1 className="text-center text-2xl font-semibold my-4">
        You need to have a More Happi Team account to join a programme
      </h1>
      <p className="text-center mt-2 mb-6 text-xl">
        We&apos;re sorry, but we no longer offer Programmes to Individuals. In order to continue
        using More Happi, you can set up a Team account. It&apos;s better value for you, and you can
        create one with as little as two people.
      </p>
      <p className="text-center text-lg">
        You can find out more about Team accounts at{" "}
        <a href={"https://morehappi.com"} target="_blank" rel="noreferrer">
          our website
        </a>
      </p>
      <p className="text-center mb-2 text-lg">
        If you have any questions, please contact{" "}
        <a href="mailto:hey@morehappi.com">hey@morehappi.com</a>
      </p>
    </div>
  );
};

const Email = ({
  onBack,
  setShowProgrammes,
  setGivenEmail,
  send,
}: {
  onBack: () => void;
  setShowProgrammes: React.Dispatch<React.SetStateAction<boolean>>;
  setGivenEmail: React.Dispatch<React.SetStateAction<boolean | null>>;
  send: (data: SendData) => void;
}) => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
  };

  const userByEmail = useGetUserByEmailQuery({ email }, { enabled: isEmailValid });

  useEffect(() => {
    setIsEmailValid(/\S+@\S+\.\S+/.test(email));
    setError(false);
  }, [email]);

  return (
    <>
      <h1 className="text-center text-2xl font-semibold my-4">
        Enter your email to see available programmes
      </h1>
      <p className="text-center mb-2 text-lg">
        You will be able to select a programme that best suits your needs and then have the option
        to create an account and pay.
      </p>
      <p className="text-center mb-2 text-lg">
        After selecting a programme, you will be able to confirm your first session with your coach.
      </p>
      <Input
        type="email"
        id="email"
        name="email"
        placeholder="Type your email address"
        onChange={(event) => setEmail(event.target.value)}
      />
      {error && (
        <div className="px-4 py-2 bg-pink rounded-xl">
          This email is already in use. Please{" "}
          <span className="underline cursor-pointer" onClick={() => onBack()}>
            login
          </span>{" "}
          or choose a different email address
        </div>
      )}
      <div className="flex gap-2">
        <input
          type="checkbox"
          id="marketingEmailCheckbox"
          checked={checked}
          onChange={handleCheckboxChange}
          className="mr-2"
        />
        <label htmlFor="marketingEmailCheckbox" className="text-sm">
          I agree to the{" "}
          <a
            href={`${WEB_ASSETS_URL}/individual-terms.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            terms and conditions
          </a>
          .
        </label>
      </div>
      <Button
        primary
        large
        className="w-full mt-8"
        disabled={!isEmailValid || !checked || error}
        onClick={() => {
          if (userByEmail?.data?.getUser?.id) {
            setError(true);
          } else {
            send({ key: "email", value: email });
            setGivenEmail(true);
            setShowProgrammes(true);
          }
        }}
      >
        Continue
      </Button>
    </>
  );
};

export const AccountDetails = () => {
  const auth = useAuth();
  const [hasAccount, setHasAccount] = useState(null);
  const [givenEmail, setGivenEmail] = useState(null);
  const [showProgrammes, setShowProgrammes] = useState(false);
  const [searchParams] = useSearchParams();
  const [enquiryStates, setEnquiryStates] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialCount, setInitialCount] = useState(900);
  const [showTimer, setShowTimer] = useState(false);
  const [bookingHold, setBookingHold] = useState(null);
  const user = useUser();
  const upsertEnquiryChoice = useUpsertEnquiryChoiceMutation();
  const upsertBookingHold = useUpsertBookingHoldMutation();

  const enquiryQuery = useGetEnquiryQuery({
    where: { id: searchParams.get("eid") },
  });

  const programme = enquiryQuery?.data?.getEnquiry?.enquiryChoices.find(
    (choice) => choice.key === "path",
  ).value;

  const programmeQuiz = useGetProgrammeQuizQuery(
    { id: `programmes/${programme}` },
    { enabled: !!programme },
  );

  const { isLoading, data } = useGetLastBookingHoldQuery(
    {
      where: {
        enquiry: {
          id: {
            equals: searchParams.get("eid"),
          },
        },
        state: { equals: BookingHoldState.Hold },
      },
    },
    { gcTime: 0 },
  );

  useEffect(() => {
    if (!isLoading && data) {
      setBookingHold(data.listBookingHolds[0]);
    }
  }, [data?.listBookingHolds[0].id]);

  useEffect(() => {
    if (user?.currentUser) {
      setHasAccount(true);
      setGivenEmail(true);
      setShowProgrammes(true);
    }
  }, []);

  useEffect(() => {
    const setInitialTime = () => {
      if (!isLoading && bookingHold) {
        setInitialCount(
          Number(
            DateTime.fromISO(bookingHold?.endsAt)
              .diff(DateTime.now())
              .toFormat("s"),
          ),
        );
        setShowTimer(true);
      }
    };
    setInitialTime();
  }, [bookingHold]);

  useEffect(() => {
    if (programmeQuiz?.data?.PageItem?.content) {
      try {
        setEnquiryStates(
          getEnquiryStates(
            formatProgrammeData(programmeQuiz.data.PageItem.content),
            !!user?.currentUser?.id,
            true,
          ),
        );
      } catch (e) {
        console.error(e);
        toast.error("An error occurred", "");
        setEnquiryStates(null);
      }
    }
  }, [programmeQuiz.data, user.currentUser]);

  const currentStep = enquiryStates?.find((s) => s.state === "package");

  const send = async ({ key, value }: { key: string; value: string }) => {
    setLoading(true);

    const _enquiryId = searchParams.get("eid");

    const existingAnswer = bookingHold.enquiry.enquiryChoices.find((choice) => choice.key === key);

    try {
      await upsertEnquiryChoice.mutateAsync({
        create: {
          enquiry: { connect: { id: _enquiryId } },
          key,
          value,
        },
        update: {
          enquiry: { connect: { id: _enquiryId } },
          key,
          value,
        },
        where: {
          id: existingAnswer?.id ?? uuid(),
        },
      });

      if (user?.currentUser) {
        await upsertBookingHold.mutateAsync({
          create: { enquiry: { connect: { id: _enquiryId } } },
          update: {
            teamMember: { connect: { id: user?.currentUser?.teamMember?.id } },
          },
          where: {
            id: bookingHold.id,
          },
        });
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  if (loading) {
    return <></>;
  } else
    return (
      <div className="my-10 w-screen">
        <div className="mx-auto">
          <HtmlMeta title="Enquiry Account" />
          <div className="flex flex-col items-center w-full px-2 xl:px-0 xl:w-[1000px] mx-auto">
            <div className="rounded-lg w-full px-4 py-6">
              <ProgressBar progress={10} />
              {showTimer ? (
                <Countdown
                  initialCount={initialCount}
                  coachName={bookingHold.coach.displayName}
                  coachHandle={bookingHold.coach.handle}
                  sessionTime={bookingHold.sessionStartsAt}
                />
              ) : (
                <div className="mt-6">
                  <LoadingSpinner />
                </div>
              )}
            </div>
            {showProgrammes && currentStep?.data && (
              <ChooseAProgramme params={searchParams} send={send} {...currentStep.data} />
            )}
            {hasAccount === null && !givenEmail && (
              <>
                <h1 className="text-center text-2xl font-semibold mb-9 mt-8">
                  Do you already have a More Happi account?
                </h1>
                <p className="text-center text-xl">
                  In order to sign up to a programme, you need to be signed into your account.
                </p>
                <p className="text-center text-xl">
                  If you do, you will be prompted to log in. If you don&apos;t, you can find out
                  more about memberships at{" "}
                  <a href={"https://morehappi.com"} target="_blank" rel="noreferrer">
                    our website.
                  </a>
                </p>
                <Button
                  primary
                  large
                  className="w-full mt-6"
                  onClick={() => {
                    setHasAccount(true);
                  }}
                >
                  Yes, I have an account
                </Button>
                <Button
                  primary
                  large
                  className="w-full mt-4"
                  tertiary
                  onClick={() => setHasAccount(false)}
                >
                  No, I don&apos;t have an account
                </Button>
              </>
            )}

            {hasAccount === true && !givenEmail && (
              <>
                <LoginForm
                  onSubmit={async (loggedInEmail) => {
                    await auth.initializeUser();
                    setHasAccount(null);
                    setGivenEmail(true);
                    setShowProgrammes(true);
                  }}
                />
                <div
                  className="mt-4 text-center underline cursor-pointer"
                  onClick={() => setHasAccount(false)}
                >
                  Don&apos;t have an account? Create one.
                </div>
              </>
            )}
            {hasAccount === false && !givenEmail && (
              // <Email
              //   onBack={() => setHasAccount(true)}
              //   setShowProgrammes={setShowProgrammes}
              //   setGivenEmail={setGivenEmail}
              //   send={send}
              // />
              // This has been added in to remove individuals from signing up
              // Rather than deleting everything, We can have this in as a stop in case we go back to allowing individuals.
              <NoAccount />
            )}
          </div>
        </div>
      </div>
    );
};
