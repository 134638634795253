import { useUser } from "$/state/user";
import { Mixpanel } from "$/tracking";
import { mean, orderBy } from "lodash";
import { Heart } from "lucide-react";
import { useState } from "react";
import { Modal } from "../Modal";
import { Button } from "../Button";
import { Stars } from "../Icons/Stars";
import cn from "classnames";
import { all } from "lodash/fp";
import {
  ListCoachSessionReviewsQuery,
  useListCoachSessionReviewsQuery,
} from "$/graphql/types.generated";

/** We take in both reviews (old style testimonials which have been imported) and sessionReviews (new style). */
export const getReviews = (
  reviews = [],
  sessionReviews: ListCoachSessionReviewsQuery["listSessionReviews"],
) => {
  const _sessionReviews =
    sessionReviews
      ?.filter((review) => review !== null)
      .map((review) => ({
        body: review?.testimonial,
        rating: review?.coachRating,
        company: review?.session?.teamMember?.company,
        isHidden: review?.isHidden,
      })) ?? [];

  const combinedReviews = [...reviews, ..._sessionReviews];

  const byLength = orderBy(combinedReviews, [({ body }) => body?.length], ["desc"]);
  const fiveStar = byLength.filter((r) => r.rating === 5);
  const fourStar = byLength.filter((r) => r.rating === 4);
  const restOfStars = orderBy(
    byLength.filter((r) => r.rating !== 4 && r.rating !== 5),
    "rating",
  );

  const meanReview = mean(combinedReviews?.map((r) => r.rating)).toFixed(1);
  const byStar = [...fiveStar, ...fourStar, ...restOfStars];

  const withoutHidden = byStar.filter((r) => !r.isHidden);

  // Filter for any more happi reviews (internal, coaches, tests, etc.)
  const withoutMH = withoutHidden.filter(
    (r) => !r.company?.name?.toLowerCase().replaceAll(" ", "").includes("morehappi"),
  );

  const top3reviews = withoutMH.slice(0, 3);

  const allReviews = [
    ...top3reviews,
    ...withoutHidden.filter(
      (r) => !top3reviews.find((t) => t.body === r.body) && r.body?.length > 0,
    ),
  ];

  return {
    reviews: allReviews,
    meanReview,
    totalCount: combinedReviews?.length,
    filtered: allReviews?.length !== byStar?.length,
  };
};

export default function CoachTestimonials({ coach }) {
  const user = useUser();
  const [showTestimonials, setShowTestimonials] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const { data, isLoading } = useListCoachSessionReviewsQuery({ coachId: coach.id });

  const { reviews, meanReview, filtered, totalCount } = getReviews(
    coach?.reviews,
    data?.listSessionReviews,
  );

  return (
    <>
      {reviews?.length > 0 ? (
        <div
          className="flex flex-col justify-start items-start md:flex-row md:justify-between md:items-center w-fit gap-x-2"
          onClick={() => {
            setShowTestimonials(true);
            Mixpanel.track("See testimonials", {
              coach: coach!.handle,
              loggedIn: !!user.currentUser,
            });
          }}
          id="show-testimonials"
        >
          <div className="flex gap-x-1 items-center cursor-pointer ">
            <div>
              <Stars stars={meanReview} size={16} />
            </div>
            <div className="text-[#605D6B] underline">({totalCount})</div>
          </div>
          <div className="underline cursor-pointer hidden md:!block">Testimonials</div>
        </div>
      ) : (
        <div className="bg-beige w-fit rounded-md px-1 py-[2px] flex gap-x-1 items-center">
          <Heart fill="#F65800" stroke="#F65800" size={16} /> New on More Happi!
        </div>
      )}

      {showTestimonials && (
        <Modal
          onClose={() => {
            setShowTestimonials(false);
            Mixpanel.track("Hide testimonials", { coach: coach!.handle });
          }}
          size="lg"
        >
          <div>
            <h1>{coach!.displayName?.split(" ")[0]}&apos;s testimonials</h1>
            <div
              className={cn("flex flex-col gap-4 text-center mb-4 max-h-[60vh]", {
                "custom-scrollbar overflow-y-scroll pr-4": viewAll,
              })}
            >
              {reviews.slice(0, viewAll ? reviews?.length : 3)?.map((review) => (
                <div>
                  <p className="md:text-[18px] mb-0">{review.body}</p>
                  <div className="font-semibold">
                    {" "}
                    Anonymous
                    {review?.company?.name
                      ? `, ${review?.company?.name.replace(/ *\([^)]*\) */g, "")}`
                      : ""}
                  </div>
                </div>
              ))}
              {viewAll && filtered && (
                <p className="text-s italic text-left">
                  *Some testimonials may not appear here due to privacy settings.
                </p>
              )}
            </div>
            <div className="flex flex-col md:flex-row items-center mt-8 gap-2">
              {!viewAll && reviews?.length > 3 && (
                <Button
                  tertiary
                  className="w-full text-center font-semibold"
                  onClick={() => setViewAll(true)}
                >
                  View all testimonials
                </Button>
              )}
              <a href={`/coach/${coach!.handle}`} className="w-full">
                <Button primary className="w-full">
                  See availability & book
                </Button>
              </a>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
